div#signIn {
    width: 30rem;
    height: min-content;

    margin: auto calc(50% - 26.5em) auto calc(50% - 6.5rem);

    padding: 1.5rem;
    border-radius: 1rem;
    background-color: var(--background-secondary);
}

div#signIn > h2 {
    margin: 0 0 1.5rem;
    width: 100%;
    text-align: center;
}

div#signIn > div#input {
    margin: 0 auto;
    max-width: 25rem;
}

div#signIn > div#input > label {
    margin-bottom: 1rem;
}

div#signIn > div#input > div#checkboxContainer {
    margin-bottom: 1rem;
}

div#signIn > div#input > button {
    width: 100%;
}

div#errorMessageBox {
    background-color: var(--status-error-bg);
    border: 1px solid var(--status-error-border);
    border-radius: 0.25rem;
    padding: 0.5rem;
    align-items: center;
    margin-bottom: 1rem;
    color: var(--status-error-text);
}

div#errorMessageBox > p {
    font-family: "IBM Plex Sans", sans-serif;
    color: var(--status-error-text);
}

div#errorMessageBox button {
    padding: 0;
    background: none;
    color: var(--status-error-text);
    text-decoration: underline;
}

div#errorMessageBox button:hover {
    opacity: 0.8;
    cursor: pointer;
}

@media only screen and (max-width: 1000px) {
    div#signIn {
        margin: auto calc(50% - 18.25em) auto calc(50% - 14.75rem);
    }
}
