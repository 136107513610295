div#passwordResetPopUp {
    width: 22.5rem;
}

div#passwordResetPopUp > div#headline {
    display: flex;
}

div#passwordResetPopUp > div#headline > h3 {
    width: calc(100% - 2.5rem);
    margin-bottom: 0.5rem;
}

div#passwordResetPopUp > div#headline > div {
    width: 2.5rem;
}

div#passwordResetPopUp > div#headline > div > img {
    width: 100%;
}

div#passwordResetPopUp > div#input {
    margin-top: 0.5rem;
}

div#passwordResetPopUp > div#input > label {
    margin-bottom: 1rem;
}

div#passwordResetPopUp > button {
    width: 100%;
    margin: 0 0.25rem;
}

div#successMessageBox {
    background-color: var(--status-success-bg);
    border: 1px solid var(--status-success-border);
    border-radius: 0.25rem;
    padding: 0.75rem;
    align-items: center;
    margin-bottom: 1rem;
    color: var(--status-success-text);
}

div#successMessageBox > p {
    font-family: "IBM Plex Sans", sans-serif;
    color: var(--status-success-text);
}

div#errorMessageBox {
    background-color: var(--status-error-bg);
    border: 1px solid var(--status-error-border);
    border-radius: 0.25rem;
    padding: 0.75rem;
    align-items: center;
    margin-bottom: 1rem;
    color: var(--status-error-text);
}

div#errorMessageBox > p {
    font-family: "IBM Plex Sans", sans-serif;
    color: var(--status-error-text);
}
