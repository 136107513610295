div#main {
    position: absolute;
    height: 100%;
    overflow: auto;
    flex-direction: row;
    display: flex;
    flex-flow: column;
    margin: 0 3rem;
    width: calc(100% - 27.5rem);
    left: 21.5rem;
}

div#main > h2#headline {
    margin-top: 1.5rem;
}

@media only screen and (max-width: 1000px) {
    div#main {
        width: calc(100% - 11rem);
        left: 5rem;
    }
}
