div#dateInput {
    width: 100%;
    display: inline-flex;
}

div#dayInput {
    width: 4.5rem;
}

div#monthInput {
    width: 4.5rem;
}

div#yearInput {
    width: 6.25rem;
}

p#day {
    margin: 0.5rem 0;
}

p#month {
    margin: 0.5rem 0;
}

p#year {
    margin: 0.5rem 0;
}

p.dateDot {
    width: 0.25rem;
    margin: 0.5rem 0.25rem 0.5rem 0.25rem;
    font-weight: 600;
}