footer {
    position: absolute;
    bottom: 0;
    height: 2.25rem;
    width: 100%;
}

footer > a#imprint {
    position: absolute;
    right: 0;
    margin: 0.5rem 1rem 0.5rem 0;
}

footer > p#copyright {
    position: absolute;
    left: 0;
    margin: 0.5rem 0 0.5rem 1rem;
}