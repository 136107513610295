div#birthdayData {
    width: 35rem;
    padding: 1.5rem;
    background-color: var(--background-secondary);
    border-radius: 1rem;
    box-shadow: 1rem 1rem 1rem rgba(38, 38, 38, 0.8);
}

div#navigationIcons {
    display: grid;
    grid-template-columns: 3rem 28rem 3rem;
    column-gap: 0.5rem;
}

div#navigationIcons > img:hover {
    cursor: pointer;
}

div#navigationIcons > img#arrowLefticon {
    grid-column: 1;
}

div#navigationIcons > img#editIcon {
    margin: 0.25rem;
    grid-column: 3;
}

div#headline {
    margin-bottom: 1rem;
}

div#headline > h3 {
    height: 2rem;
    padding: 0.5rem 0.5rem 0.5rem 1rem;
}

div#data > div {
    margin-bottom: 1rem;
}

div#birthdayDate {
    display: inline-flex;
}

p#day {
    margin: 0.5rem 0;
}

p#month {
    margin: 0.5rem 0;
}

p#year {
    margin: 0.5rem 0;
}

p.dateDot {
    width: 0.25rem;
    margin: 0.5rem 0.25rem 0.5rem 0.25rem;
    font-weight: 600;
}
