div#autoLogOut {
    background-color: var(--background-accent);
    right: 0;
    width: 12.5rem;
    float: right;
    position: fixed;
    top: 0;
    padding: 1rem 1rem 0.5rem 0.5rem;
    border-radius: 0 0 0 0.5rem;
}

div#autoLogOut > p {
    color: var(--text-tertiary);
}

div#main {
    position: absolute;
    height: 100%;
    overflow: auto;
    flex-direction: row;
    display: flex;
    flex-flow: column;
    margin: 0 3rem;
    width: calc(100% - 27.5rem);
    left: 21.5rem;
}

div#main > h2#headline {
    margin-top: 1.5rem;
}

/* Password Management */
div#main > div#passwordManagerList {
    height: 20%;
    width: 100%;
    max-width: 25rem;

    margin: 1rem 0 1.25rem 0;
    float: left;

    flex: 1 0 auto;
    display: flex;
    flex-flow: column;
}

/* Platform Show */
div#passwordData > div {
    top: 15%;
    right: 7.5%;
    position: fixed;
}

@media only screen and (max-width: 1650px) {
    div#passwordData > div {
        top: 15%;
        right: 5rem;
    }
}

@media only screen and (max-width: 1000px) {
    div#main {
        width: calc(100% - 11rem);
        left: 5rem;
    }
}

@media only screen and (max-width: 575px) {
    div#main > h2#headline {
        margin-top: 5rem;
    }
}