div#userData {
    width: 34rem;
    padding: 1.5rem;
    background-color: var(--background-secondary);
    border-radius: 1rem;
    box-shadow: 1rem 1rem 1rem rgba(38, 38, 38, 0.8);
}

div#navigationIcons {
    display: grid;
    grid-template-columns: 3rem 27rem 3rem;
    column-gap: 0.5rem;
}

div#navigationIcons > img:hover {
    cursor: pointer;
}

div#navigationIcons > img#arrowLefticon {
    grid-column: 1;
}

div#navigationIcons > img#editIcon {
    margin: 0.25rem;
    grid-column: 3;
}

h2#headline {
    font-size: 2rem;
    line-height: 3rem;
}

div#headline {
    margin-bottom: 1rem;
}

div#data > p {
    height: 1.25rem;
    margin-bottom: 1rem;
}

div#data > p.editable {
    height: 1.5rem;
    margin-bottom: 1rem;
    padding: 0.5rem 2rem 0.5rem 1rem;
}

@media only screen and (max-width: 2500px) {
    div#userDataBackground {
        position: fixed;
        top: 0;
        width: calc(100% - 20rem);
        left: 20rem;
        height: 100%;
        background-color: rgba(50, 50, 50, 0.6);

        display: flex;
        justify-content: center;
        align-items: center;
    }
}

@media only screen and (max-width: 1000px) {
    div#userDataBackground {
        width: calc(100% - 3.5rem);
        left: 3.5rem;
    }
}
