div#userData {
    width: 34rem;
    padding: 1.5rem;
    background-color: var(--background-secondary);
    border-radius: 1rem;
    box-shadow: 1rem 1rem 1rem rgba(38, 38, 38, 0.8);
}

div#navigationIcons {
    display: grid;
    grid-template-columns: 3rem 27rem 3rem;
    column-gap: 0.5rem;
}

div#navigationIcons > img:hover {
    cursor: pointer;
}

div#navigationIcons > img#arrowLefticon {
    grid-column: 1;
}

div#navigationIcons > img#editIcon {
    margin: 0.25rem;
    grid-column: 3;
}

h2#headline {
    font-size: 2rem;
    line-height: 3rem;
}

div#headline {
    margin-bottom: 1rem;
}

div#data > div {
    margin-bottom: 1rem;
}

/* PopUp */

div#popUpBackground {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: rgba(100, 100, 100, 0.5);
}

div#popUpBackground > div.popUp {
    position: absolute;
    top: 50%;

    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);

    padding: 1.5rem;
    border-radius: 1rem;
    background-color: var(--background-primary);
}

/* Warning popUp */

div#warningPopUp {
    width: 22rem;
    left: calc(50% + 10rem);
}

div#warningPopUp > p {
    margin-bottom: 1rem;
    word-wrap: break-word;
}

div#warningPopUp > p > span {
    color: var(--text-accent);
}

div#warningPopUp > button {
    width: calc(50%);
    margin: 0 calc(50% / 2);
}

@media only screen and (max-width: 2500px) {
    div#userDataBackground {
        position: fixed;
        top: 0;
        width: calc(100% - 20rem);
        left: 20rem;
        height: 100%;
        background-color: rgba(50, 50, 50, 0.6);

        display: flex;
        justify-content: center;
        align-items: center;
    }
}

@media only screen and (max-width: 1000px) {
    div#userDataBackground {
        width: calc(100% - 3.5rem);
        left: 3.5rem;
    }

    div#warningPopUp {
        left: calc(50% + 1.25rem);
    }
}