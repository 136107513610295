div#popUpBackground {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: rgba(100, 100, 100, 0.5);
}

div#popUpBackground > div.popUp {
    position: absolute;
    left: 50%;
    top: 50%;

    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);

    padding: 25px;
    border-radius: 15px;
    background-color: var(--background-primary);
}

div#passwordManagerKeyPopUp {
    width: 400px;
}

div#passwordManagerKeyPopUp > h4 {
    margin: 15px 0 10px 0;
    text-align: center;
}

div#passwordManagerKeyPopUp > p#important {
    text-align: center;
}

div#passwordManagerKeyPopUp > p#info {
    margin-top: 15px;
}

div#passwordManagerKeyPopUp > div {
    margin-top: 15px;
}

div#passwordManagerKeyPopUp > div > label {
    margin-bottom: 15px;
}

div#passwordManagerKeyPopUp > div > button {
    width: 100%;
}

div#errorMessageBox {
    background-color: var(--status-error-bg);
    border: 1px solid var(--status-error-border);
    border-radius: 5px;
    padding: 10px;
    align-items: center;
    margin-bottom: 15px;
    color: var(--status-error-text);
}

div#errorMessageBox > p {
    font-family: "IBM Plex Sans", sans-serif;
    color: var(--status-error-text);
}

div#errorMessageBox button {
    padding: 0;
    background: none;
    color: var(--status-error-text);
    text-decoration: underline;
}

div#errorMessageBox button:hover {
    opacity: 0.8;
    cursor: pointer;
}
