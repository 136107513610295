div#emailNotVerifiedBanner {
    height: 7.5rem;
    padding: 1.5rem;
    background: var(--background-secondary);
    width: 30rem;
    border: var(--red) 2px solid;
    border-radius: 0.5rem;
    margin-top: 3rem;
}

div#emailNotVerifiedBanner > div#headline {
    width: 100%;
    height: 2.5rem;
    margin-bottom: 0.5rem;
}

div#emailNotVerifiedBanner > div#headline > h4 {
    width: calc(100% - 3rem);
    float: left;
}

div#emailNotVerifiedBanner > div#headline > div#refresh {
    width: 2.5rem;
    height: 2.5rem;
    float: right;
}

div#emailNotVerifiedBanner > div#headline > div#refresh.load {
    -webkit-animation:spin 1s linear infinite;
    -moz-animation:spin 1s linear infinite;
    animation:spin 1s linear infinite;
}

@-moz-keyframes spin { 
    100% { -moz-transform: rotate(360deg); } 
}
@-webkit-keyframes spin { 
    100% { -webkit-transform: rotate(360deg); } 
}
@keyframes spin { 
    100% { 
        -webkit-transform: rotate(360deg); 
        transform:rotate(360deg); 
    } 
}


div#emailNotVerifiedBanner > p {
    margin-bottom: 1rem;
}

div#emailNotVerifiedBanner > div#footer {
    width: 100%;
    height: 2.75rem;
}

div#emailNotVerifiedBanner > div#footer > p {
    width: 50%;
    margin: 0.75rem 0;
    float: left;
}

div#emailNotVerifiedBanner > div#footer > button {
    float: right;
}