div#headlineList {
    background-color: var(--background-secondary);
    border-radius: 0.5rem;
    margin-top: 1.5rem;
    margin-bottom: 0.5rem;

    display: grid;

    height: 6.5rem;
    width: 76rem;
    padding: 0.5rem 1.5rem;
    grid-template-columns:  14rem 18.75rem 11rem 11rem 6.25rem 6.25rem;
}

div#headlineList > div > div {
    height: 2.25rem;
    margin: 0.5rem 0;
    display: inline-flex;
    align-items: center;
}

div#headlineList > div > div.search {
    width: calc(100% - 1rem);
}

div#headlineList > div > div > img {
    height: 100%;
}

div#headlineList > div > div > img:hover {
    cursor: pointer;
}

div#headlineList > div > div > h4 {
    float: left;
    margin-left: 0.5rem;
}

div#list {
    height: 50%;
    width: 79rem;

    margin-bottom: 1.5rem;
    float: left;

    flex: 1 0 auto;
    display: flex;
    flex-flow: column;
}

div#list > div {
    height: 100%;

    display: flex;
    flex-flow: column;

    padding: 0 1.5rem;
    background-color: var(--background-secondary);
    border-radius: 10px;
}

div#list > div > hr {
    height: 1.5rem;
    width: calc(100% + 3rem);

    border: none;
    position: relative;
    flex-shrink: 0;
}

div#list > div > hr#topBorder {
    background: linear-gradient(
        to bottom,
        rgba(30, 30, 30, 1),
        rgba(0, 0, 0, 0)
    );
    border-radius: 0.5rem 0.5rem 0 0;
    margin: 0 0 -1.5rem -1.5rem;
}

div#list > div > hr#bottomBorder {
    background: linear-gradient(to top, rgba(30, 30, 30, 1), rgba(0, 0, 0, 0));
    border-radius: 0 0 0.5rem 0.5rem;
    margin: -1.5rem 0 0 -1.5rem;
}

div#list > div > div {
    height: 100%;
    padding: 1.5rem 0;
    overflow-y: auto;
    overflow-x: hidden;

    scrollbar-width: thin;
    scrollbar-color: var(--background-floating) var(--background-tertiary);
}

div.listElement {
    width: 100%;
    height: calc(4rem + 1px);

    display: grid;
    align-items: center;
}

div.listElement > hr {
    color: var(--background-floating);
    margin: 0;
    border: none;
    border-top: 1px var(--background-floating) solid;
}

div.listElement > div {
    width: 100%;
    height: 3rem;
    margin: 0.5rem 0 0.5rem 0.5rem;

    display: grid;
    align-items: center;
    grid-template-columns:  14rem 18.75rem 11rem 11rem 6.25rem 6.25rem 7.25rem;
}

div.listElement > div > p {
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

div.listElement.selectedElement > div > p {
    color: var(--text-accent);
}

div.listElement > div > img {
    width: 2rem;
}

div#bottomButton {
    width: 18.75rem;
    margin-bottom: 4rem;
}

div#bottomButton > button {
    width: 100%;
}

@media only screen and (max-width: 1750px) {
    div#headlineList {
        width: 65rem;
        grid-template-columns:  14rem 18.75rem 11rem 6.25rem 6.25rem;
    }

    div#headlineList > div.firstName {
        display: none;
    }

    div#list {
        height: 50%;
        width: 68rem;
    }

    div.listElement > div {
        grid-template-columns:  14rem 18.75rem 11rem 6.25rem 6.25rem 7.25rem;
    }

    div.listElement > div > p.firstName {
        display: none;
    }
}

@media only screen and (max-width: 1550px) {
    div#headlineList {
        width: 54rem;
        grid-template-columns:  14rem 18.75rem 6.25rem 6.25rem;
    }

    div#headlineList > div.lastName {
        display: none;
    }

    div#list {
        height: 50%;
        width: 57rem;
    }

    div.listElement > div {
        grid-template-columns:  14rem 18.75rem 6.25rem 6.25rem 7.25rem;
    }

    div.listElement > div > p.lastName {
        display: none;
    }
}

@media only screen and (max-width: 1450px) {
    div#headlineList {
        width: 47.75rem;
        grid-template-columns:  14rem 18.75rem 6.25rem;
    }

    div#headlineList > div.status {
        display: none;
    }

    div#list {
        height: 50%;
        width: 50.75rem;
    }

    div.listElement > div {
        grid-template-columns:  14rem 18.75rem 6.25rem 7.25rem;
    }

    div.listElement > div > p.status {
        display: none;
    }
}

@media only screen and (max-width: 1275px) {
    div#headlineList {
        width: 29rem;
        grid-template-columns:  14rem 6.25rem;
    }

    div#headlineList > div.email {
        display: none;
    }

    div#list {
        height: 50%;
        width: 32rem;
    }

    div.listElement > div {
        grid-template-columns:  14rem 6.25rem 7.25rem;
    }

    div.listElement > div > p.email {
        display: none;
    }
}
