div#passwordData {
    width: 35rem;
    padding: 1.5rem;
    background-color: var(--background-secondary);
    border-radius: 1rem;
    box-shadow: 1rem 1rem 1rem rgba(38, 38, 38, 0.8);
}

div#navigationIcons {
    display: grid;
    grid-template-columns: 3rem 24.5rem 3rem 3rem;
    column-gap: 0.5rem;
}

div#navigationIcons > img:hover {
    cursor: pointer;
}

div#navigationIcons > img#arrowLefticon {
    grid-column: 1;
}

div#navigationIcons > img#deleteIcon {
    margin: 0.25rem;
    grid-column: 3;
}

div#navigationIcons > img#saveIcon {
    margin: 0.25rem;
    grid-column: 4;
}

div#headline {
    height: 3.5rem;

    margin-bottom: 1rem;
}

div#data > label {
    margin-bottom: 1rem;
}

div#data > label:last-of-type {
    margin-bottom: 0;
}

/* PopUp */

div#popUpBackground {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: rgba(100, 100, 100, 0.5);
}

div#popUpBackground > div.popUp {
    position: absolute;
    left: 50%;
    top: 50%;

    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);

    padding: 1.5rem;
    border-radius: 1rem;
    background-color: var(--background-primary);
}

/* Delete popUp */

div#deletePopUp {
    width: 22.5rem;
}

div#deletePopUp > p {
    margin-bottom: 1rem;
    word-wrap: break-word;
}

div#deletePopUp > p > span {
    color: var(--text-accent)
}

div#deletePopUp > button {
    width: calc(50% - 0.5rem);
    margin: 0 0.25rem;
}

@media only screen and (max-width: 1500px) {
    div#passwordDataBackground {
        position: fixed;
        top: 0;
        width: calc(100% - 20rem);
        left: 20rem;
        height: 100%;
        background-color: rgba(50, 50, 50, 0.6);

        display: flex;
        justify-content: center;
        align-items: center;
    }
}

@media only screen and (max-width: 1000px) {
    div#passwordDataBackground {
        width: calc(100% - 3.5rem);
        left: 3.5rem;
    }
}