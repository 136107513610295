div#main {
    width: 55%;
    margin: 5rem auto 0 auto;

    display: flex;
}

div#iconsBeforeContent {
    height: 100%;
    width: 3rem;
    margin-right: 1.5rem;
}

div#imprintContent {
    width: calc(100% - 4.5rem);
}

div#imprintContent > h2 {
    margin-bottom: 1rem;
}

div#imprintContent > div > h4 {
    margin-bottom: 0.5rem;
}

div#imprintContent > div > div {
    margin-bottom: 1rem;
}

div#imprintContent > div > h3 {
    margin-bottom: 0.5rem;
}

div#imprintContent > div > p {
    margin-bottom: 1rem;
    text-align: justify;
}

@media only screen and (max-width: 2000px) {
    div#main {
        width: 65%;
    }
}

@media only screen and (max-width: 1250px) {
    div#main {
        width: 75%;
    }
}
