/* InputFields */
label#inputLabel {
    display: inline-flex;
    width: 100%;
}

label#inputLabel > input {
    color: var(--header-accent);
    background-color: var(--text-input-bg);

    font-family: "IBM Plex Sans", sans-serif;
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 2rem;
    padding: calc(0.5rem - 1px) calc(0.5rem - 1px) calc(0.5rem - 1px) calc(1rem - 1px);

    border-radius: 0.25rem;
    border: 1px solid var(--text-input-border);
    transition: border-color 0.2s ease-in-out;
    width: calc(100% - 1.5rem);
}

label#inputLabel > input:hover {
    border: 1px solid var(--text-input-border-hover);
    opacity: 0.9;
    cursor: pointer;
}

label#inputLabel > input:focus-visible {
    border: 1px solid var(--brand-color);
    outline: none;
}

label#inputLabel > input:required {
    border: 1px solid var(--text-input-warning-border);
    outline: none;
}

label[data-tip]#inputLabel {
    position: relative;
    --dataTipBefore-top: calc(2rem + 1.5rem);
    --dataTipAfter-top: calc(2rem + 1.5rem + 0.25rem);
}

label[data-tip]#inputLabel:before {
    content: "";
    display: none;
    border-left: 0.25rem solid transparent;
    border-right: 0.25rem solid transparent;
    border-bottom: 0.25rem solid var(--text-input-warning-border);
    position: absolute;
    top: var(--dataTipBefore-top);
    left: 2rem;
    z-index: 8;
    font-size: 0;
    line-height: 0;
    width: 0;
    height: 0;
}

label[data-tip]#inputLabel:after {
    font-family: "IBM Plex Sans", sans-serif;
    display: none;
    content: attr(data-tip);
    position: absolute;
    top: var(--dataTipAfter-top);
    left: 0;
    padding: 0.25rem 0.75rem;
    background: var(--text-input-warning-border);
    color: var(--text-primary);
    z-index: 9;
    font-size: 1rem;
    line-height: 1.5rem;
    border-radius: 0.25rem;
    white-space: nowrap;
    word-wrap: normal;
}

label[data-tip]#inputLabel.tooltipRequired:hover:before,
label[data-tip]#inputLabel.tooltipRequired:hover:after {
    display: block;
}
