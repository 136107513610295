div#currentUserData {
    display: inline-grid;
    width: 22rem;
    height: min-content;
    padding: 1.5rem;
    border-radius: 0.5rem;
    margin: 0 1.5rem 1.5rem 0;

    background-color: var(--background-secondary);
}

div#currentUserData > h4 {
    margin-bottom: 0.25rem;
}

div#currentUserData > div {
    margin-bottom: 0.5rem;
}
