div#birthdayData {
    width: 35rem;
    padding: 1.5rem;
    background-color: var(--background-secondary);
    border-radius: 1rem;
    box-shadow: 1rem 1rem 1rem rgba(38, 38, 38, 0.8);
}

div#navigationIcons {
    display: grid;
    grid-template-columns: 3rem 28rem 3rem;
    column-gap: 0.5rem;
}

div#navigationIcons > img:hover {
    cursor: pointer;
}

div#navigationIcons > img#arrowLefticon {
    grid-column: 1;
}

div#navigationIcons > img#saveIcon {
    margin: 0.25rem;
    grid-column: 3;
}

h2#headline {
    font-size: 2rem;
    line-height: 2.75rem;
}

div#headline {
    height: 3.5rem;
    
    margin-bottom: 1rem;
}

div#data > div {
    margin-bottom: 1rem;
}
