/* Begin of combobox */
div.containerCombobox {
    height: 2.5rem;
    display: inline-block;
    position: relative;
}

div.containerCombobox > label {
    width: 100%;
    display: inline-block;
}

div.containerCombobox > label > input[type="text"] {
    width: calc(100% - 3rem);
    height: 1.25rem;
    font-family: "Roboto", sans-serif;
    font-size: 1rem;
    line-height: 1.25rem;
    padding: calc(0.5rem - 1px) calc(2rem - 1px) calc(0.5rem - 1px) calc(1rem - 1px);

    color: var(--text-primary);
    background-color: var(--text-input-bg);

    border-radius: 5px;
    border: 1px solid var(--text-input-border);
    transition: border-color 0.2s ease-in-out;

    user-select: none;
    -webkit-user-select: none;
}

div.containerCombobox > label > input[type="text"]:hover {
    border: 1px solid var(--text-input-border-hover);
    opacity: 0.9;
    cursor: pointer;
}

div.containerCombobox > label > input[type="text"]:focus-visible {
    border: 1px solid var(--brand-color);
    outline: none;
}

div.containerCombobox > label > i {
    height: 2.25rem;
    width: 2rem;
    display: inline-block;
    top: 0.75rem;
    margin-top: -0.75rem;
    margin-left: calc(-2rem + -1px);

    border-left: 1px solid var(--brand-color);
    position: relative;
    z-index: 0;
}

div.containerCombobox > label > i > img {
    width: 1.25rem;
    margin: 0.5rem 0.25rem;
}

div.options {
    display: none;
}

div.optionsShow {
    color: var(--text-primary);
    background-color: var(--background-secondary);

    padding: 0.25rem 0;
    width: 100%;
    border-radius: 0.25rem;

    top: 3rem;

    position: absolute;
    z-index: 1;

    border: var(--text-input-border) solid 1px;

    display: block;
}

div.containerCombobox > div.optionsShow > a {
    width: calc(100% - 1.25rem);
    height: 1.25rem;
    padding: 0.25rem 0.25rem 0.25rem 1rem;

    display: inline-block;

    color: var(--text-primary);
}

div.containerCombobox > div.optionsShow > a:hover {
    background-color: var(--comboBox-options-hover);
}
