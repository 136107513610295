label.checkBox {
    display: inline-block;
    position: relative;
    padding-left: 2.5rem;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    height: 30px;
}

label.checkBox > p {
    margin: 0.25rem 0 0.25rem;
    color: var(--text-primary);
}

label.checkBox > input {
    appearance: none;
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    margin: 0;
}

label.checkBox > span {
    position: absolute;
    top: 0;
    left: 0;
    height: 2rem;
    width: 2rem;
    background-color: var(--checkBox-bg);
    border-radius: 0.25rem;
    margin: 0;
}

label.checkBox:hover > input ~ span {
    background-color: var(--checkBox-bg-hover);
}

label.checkBox > input:checked ~ span {
    background-color: var(--brand-color);
}

label.checkBox > span > img {
    position: absolute;
    display: none;
    margin: 0.25rem;
}

label.checkBox > input:checked ~ span > img {
    display: block;
}