div#headlineNavigation {
    width: 24rem;
    margin: 1.5rem 0;

    display: grid;
    grid-template-columns: 8rem 11rem 5rem;
}

div#headlineNavigation > div#buttons {
    display: grid;
    width: 8rem;
    height: 100%;

    grid-template-rows: 2.5rem 1.25rem 2.5rem;
}

div#headlineNavigation > div#month {
    width: 11rem;
    height: 6.25rem;
}

div#headlineNavigation > div#year {
    width: 5rem;
    height: 6.25rem;
}

div#headlineNavigation > div > div.arrows {
    width: 100%;
    height: calc((100% - 2rem) / 2);

    display: inline-flex;
    justify-content: center;
}

div#headlineNavigation > div > div.arrows > img {
    width: 2rem;
    height: 100%;
}

div#headlineNavigation > div > h3 {
    width: 100%;
    text-align: center;
}

div#calendar {
    height: 100%;
    display: flex;
    flex-direction: column;
    border: 1px solid var(--border-primary);
    margin: 0 0.5rem 1rem 0.5rem;
}

div#calendar > div.calendarWeekdays {
    display: flex;
    width: 100%;
    height: 2.5rem;
}

div#calendar > div.calendarWeek {
    display: flex;
    width: 100%;
    height: 10%;
    flex-grow: 1;
}

div#calendar > div > div {
    width: 10%;
    flex-grow: 1;

    border-collapse: collapse;
    border: 1px solid var(--border-primary);
}

div#calendar > div > div.calendarWeekNumber {
    width: 2.5rem;
    flex-grow: initial;
    background-color: var(--background-secondary);
}

div#calendar > div > div.calendarWeekNumber > p {
    width: 2.5rem;
    margin-top: 0.5rem;
    font-family: "IBM Plex Sans", sans-serif;
    text-align: center;
    color: var(--text-accent);
}

div#calendar > div > div.weekday > p {
    width: 100%;
    margin: 0.5rem;
}

div#calendar > div > div.weekday > p > span.weekdayShort {
    display: none;
}

div#calendar > div > div.weekday > p > span.weekdayLong {
    display: inline;
}

div#calendar > div > div.calendarItem {
    justify-content: start;
    align-content: start;
}

div#calendar > div.calendarWeek > div.calendarItem.placeholder > p {
    opacity: 0.3;
}

div#calendar > div.calendarWeek > div.calendarItem.today > p {
    background-color: var(--text-accent);
    color: var(--text-tertiary);
    font-weight: bold;
}

div#calendar > div.calendarWeek > div.calendarItem > div {
    height: calc(100% - 3.5rem);
    width: calc(100% - 1.25rem);
    margin-top: 0.5rem;
    margin-left: 1rem;

    overflow-y: scroll;
}

div#calendar > div.calendarWeek > div.calendarItem.placeholder > div > div > p {
    opacity: 0.7;
}

div#calendar > div.calendarWeek > div.calendarItem > div > div.selected > p {
    color: var(--text-accent);
    opacity: 0.8;
}

div#calendar > div.calendarWeek > div.calendarItem > div > div > p:hover {
    color: var(--text-accent);
    opacity: 0.8;

    cursor: pointer;
}

div#calendar > div.calendarWeek > div.calendarItem > p.calendarDay {
    width: 1.25rem;
    padding: 0.5rem;
    margin: 0.5rem 0 0.5rem 0.5rem;
    font-family: "IBM Plex Sans", sans-serif;
    border-radius: 0.25rem;
    text-align: center;
}

div#bottomButton {
    width: 20rem;
    margin-bottom: 3rem;
}

div#bottomButton > button {
    width: 100%;
}

div#birthdayDataBackground {
    position: fixed;
    top: 0;
    width: calc(100% - 20rem);
    left: 20rem;
    height: 100%;
    background-color: rgba(50, 50, 50, 0.6);

    display: flex;
    justify-content: center;
    align-items: center;
}

@media only screen and (max-width: 1100px) and (min-width: 1000px) {
    div#calendar > div > div.weekday > p > span.weekdayShort {
        display: inline;
    }

    div#calendar > div > div.weekday > p > span.weekdayLong {
        display: none;
    }
}

@media only screen and (max-width: 1000px) {
    div#birthdayDataBackground {
        width: calc(100% - 3.5rem);
        left: 3.5rem;
    }
}

@media only screen and (max-width: 650px) {
    div#calendar > div > div.weekday > p > span.weekdayShort {
        display: inline;
    }

    div#calendar > div > div.weekday > p > span.weekdayLong {
        display: none;
    }
}
