div#headlineNavigation {
    width: 24rem;
    margin: 1.5rem 0;

    display: grid;
    grid-template-columns: 8rem;
}

div#headlineNavigation > div#buttons {
    display: grid;
    width: 8rem;
    height: 100%;

    grid-template-rows: 2.5rem 1.25rem 2.5rem;
}

div#headlineList {
    background-color: var(--background-secondary);
    border-radius: 0.5rem;
    margin-bottom: 0.5rem;

    display: grid;

    height: 2.5rem;
    width: 42.75rem;
    padding: 0 1.5rem;
    grid-template-columns: 15rem 12.5rem 6rem 4rem;
}

div#headlineList > div {
    height: 2rem;
    margin: 0.25rem 0;
    display: inline-flex;
    align-items: center;
}

div#headlineList > div > img {
    height: 100%;
}

div#headlineList > div > img:hover {
    cursor: pointer;
}

div#headlineList > div > h4 {
    float: left;
    margin-left: 0.5rem;
}

div#list {
    height: 50%;
    width: 45.75rem;

    margin-bottom: 1.5rem;
    float: left;

    flex: 1 0 auto;
    display: flex;
    flex-flow: column;
}

div#list > div {
    height: 100%;

    display: flex;
    flex-flow: column;

    padding: 0 1.5rem;
    background-color: var(--background-secondary);
    border-radius: 0.5rem;
}

div#list > div > hr {
    height: 1.5rem;
    width: calc(100% + 3rem);

    border: none;
    position: relative;
    flex-shrink: 0;
}

div#list > div > hr#topBorder {
    background: linear-gradient(
        to bottom,
        rgba(30, 30, 30, 1),
        rgba(0, 0, 0, 0)
    );
    border-radius: 0.5rem 0.5rem 0 0;
    margin: 0 0 -1.5rem -1.5rem;
}

div#list > div > hr#bottomBorder {
    background: linear-gradient(to top, rgba(30, 30, 30, 1), rgba(0, 0, 0, 0));
    border-radius: 0 0 0.5rem 0.5rem;
    margin: -1.5rem 0 0 -1.5rem;
}

div#list > div > div {
    height: 100%;
    padding: 1.5rem 0;
    overflow-y: auto;
    overflow-x: hidden;

    scrollbar-width: thin;
    scrollbar-color: var(--background-floating) var(--background-tertiary);
}

div.subHeadline {
    margin-top: 1.25rem;
    height: 2rem;
}

div.listElement {
    width: 100%;
    height: calc(3.5rem + 1px);

    display: grid;
    align-items: center;
}

div.listElement > hr {
    color: var(--background-floating);
    margin: 0;
    border: none;
    border-top: 1px var(--background-floating) solid;
}

div.listElement > div {
    width: 100%;
    height: 2.5rem;
    margin: 0.5rem 0;

    display: grid;
    align-items: center;
    grid-template-columns: 15rem 12.5rem 6rem 8.5rem;
}

div.listElement > div > p {
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

div.listElement.selected > div > p {
    color: var(--text-accent);
}

div#bottomButton {
    width: 20rem;
    margin-bottom: 3rem;
}

div#bottomButton > button {
    width: 100%;
}

div#birthdayData {
    position: fixed;
    top: 30%;
    right: 15%;
}

@media only screen and (max-width: 2200px) {
    div#birthdayData {
        right: 10%;
    }
}

@media only screen and (max-width: 2000px) {
    div#birthdayData {
        right: 5%;
    }
}

@media only screen and (max-width: 1850px) {
    div#birthdayDataBackground {
        position: fixed;
        top: 0;
        width: calc(100% - 20rem);
        left: 20rem;
        height: 100%;
        background-color: rgba(50, 50, 50, 0.6);
    
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    div#birthdayData {
        right: 5%;
    }
}

@media only screen and (max-width: 1100px) {
    div#headlineList {
        width: 34rem;
        grid-template-columns: 15rem 12.5rem 4rem;
    }

    div#headlineList > div#headlineAge {
        display: none;
    }

    div#list {
        width: 37rem;
    }

    div.listElement > div {
        grid-template-columns: 15rem 12.5rem 6rem;
    }

    div.listElement > div > p.age {
        display: none;
    }
}

@media only screen and (max-width: 1000px) {
    div#birthdayDataBackground {
        width: calc(100% - 3.5rem);
        left: 3.5rem;
    }
}