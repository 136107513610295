div#navigation {
    width: 20rem;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    overflow-x: hidden;
    margin-right: 1.5rem;
    position: absolute;

    background-color: var(--background-secondary);
    box-shadow: 0.5rem 0 0.5rem rgba(38, 38, 38, 0.8);
    transition: all .5s ease-in-out;
}

div#navigation > div#topNavigation {
    margin-top: 1.5rem;
    width: 20rem;
}

div#navigation > div > h3#logo {
    width: 20rem;
    margin: 0 auto;

    font-family: 'IBM Plex Mono', sans-serif;
    font-size: 2.5rem;
    color: var(--header-primary);
    text-align: center;
}

div#navigation > div > h3#logo > b {
    color: var(--header-accent);
}

div#navigation > div > nav > ul {
    padding: 0;
}

div#navigation > div > nav > ul hr#seperator {
    margin: 0;
    height: 2px;
    background-color: var(--background-floating);
    border: none;
}

div#navigation > div > nav > ul > li {
    height: 2.75rem;
    width: 20rem;
    list-style: none;

    display: block;
}

div#navigation > div > nav > ul > li > a.navigation > p {
    margin-left: 2.25rem;
    width: 15rem;

    color: var(--text-primary);
    list-style: none;
    text-decoration: none;
    padding: 0.75rem 0;

    display: inline-block;
}

div#navigation > div > nav > ul > li > a.navigation:hover {
    cursor: pointer;
}

div#navigation > div > nav > ul > li.glow > a > p {
    color: var(--text-accent);
}

div#navigation > div > nav > ul > li#signOut > a > p {
    color: var(--signOut);
}

div#navigation > div > nav > ul > li > a > span {
    height: 2.75rem;
    width: 0;
    float: right;
    margin-left: 0;

    opacity: 0;
    background: linear-gradient(to left, var(--brand-color), var(--background-secondary));
    transition-duration: .5s;
}

div#navigation > div > nav > ul > li.glow > a > span {
    width: 2.25rem;
    margin-left: -2.25rem;

    opacity: 0.70;
}

div#navigation > div > nav > ul > li:hover > a > span {
    width: 3rem;
    float: right;
    margin-left: -3rem;

    opacity: 0.90;
    transition: .5s;
}

div#navigation > div > nav > ul > li#signOut > a > span {
    background: linear-gradient(to left, var(--signOut), var(--background-secondary));
}

div#sideBar {
    width: 3.5rem;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 2;
    overflow-x: hidden;
    margin-right: 1.5rem;
    position: absolute;

    background-color: var(--background-secondary);
    box-shadow: 0.5rem 0 0.5rem rgba(38, 38, 38, 0.8);
    transition: all .5s ease-in-out;
    display: none;
}

div#sideBar > div#showNavigation {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2.5rem;
    height: 2.5rem;
    margin: 0.75rem 0.5rem;

    cursor: pointer;
    transition: all .5s ease-in-out;
}

div#sideBar > div#showNavigation > div.burgerMenuBtn,
div#sideBar > div#showNavigation > div.burgerMenuBtn::before,
div#sideBar > div#showNavigation > div.burgerMenuBtn::after {
    content: '';
    position: absolute;
    width: 2.5rem;
    height: 0.25rem;
    background: #FFFFFF;
    border-radius: 0.125rem;
    transition: all .5s ease-in-out;
}

div#sideBar > div#showNavigation > div.burgerMenuBtn::before {
    transform: translateY(-0.75rem);
}

div#sideBar > div#showNavigation > div.burgerMenuBtn::after {
    transform: translateY(0.75rem);
}

/* ANIMATION */
div#sideBar > div#showNavigation.open > div.burgerMenuBtn {
    transform: translateX(-2.5rem);
    background: transparent;
}

div#sideBar > div#showNavigation.open > div.burgerMenuBtn::before {
    transform: rotate(45deg) translate(1.75rem, -1.75rem);
}

div#sideBar > div#showNavigation.open > div.burgerMenuBtn::after {
    transform: rotate(-45deg) translate(1.75rem, 1.75rem);
}

@media only screen and (max-width: 1000px) {
    div#navigation {
        left: 3.5rem;
        width: 0;
        margin: 0;
    }

    div#navigation.open {
        width: 20rem;
        margin-right: 1.5rem;
    }

    div#sideBar {
        display: block;
    }
}