div#emailVerify {
    align-self: center;

    width: 28rem;
    background-color: var(--background-secondary);
    margin: 0 auto;
    padding: 1.5rem;
    border-radius: 1rem;

    box-shadow: 0 2px 1rem 0 rgba(0, 0, 0, 0.2);
}

div#emailVerify > div#title {
    margin: 0 auto 1rem auto;
    width: 100%;
}

div#emailVerify > div#title > h1 {
    margin: 0;
    text-align: center;
}

div#emailVerify > div#title > h3 {
    margin-top: 1rem;
}

div#emailVerify > div#input {
    margin: 0 auto;
    width: 100%;
}

div#emailVerify > div#input > p {
    margin-left: 0.5rem;
    margin-bottom: 1.25rem;
}

div#emailVerify > div#input > label {
    margin-bottom: 1.25rem;
}

div#emailVerify > div#input > button {
    width: 100%;
    margin-bottom: 0.25rem;
}

div#successMessageBox {
    background-color: var(--status-success-bg);
    border: 1px solid var(--status-success-border);
    border-radius: 0.25rem;
    padding: 0.75rem;
    align-items: center;
    margin-bottom: 1rem;
    color: var(--status-success-text);
}

div#successMessageBox > p {
    font-family: "IBM Plex Sans", sans-serif;
    color: var(--status-success-text);
}

div#errorMessageBox {
    background-color: var(--status-error-bg);
    border: 1px solid var(--status-error-border);
    border-radius: 0.25rem;
    padding: 0.5rem;
    align-items: center;
    margin-bottom: 1rem;
    color: var(--status-error-text);
}

div#errorMessageBox > p {
    font-family: "IBM Plex Sans", sans-serif;
    color: var(--status-error-text);
}
