.theme-dark {
    --background-primary: #3f3f3f;
    --background-secondary: #36393f;
    --background-tertiary: #252525;
    --background-floating: #666666;
    --background-accent: #e6a100;

    --header-primary: #ffffff;
    --header-secondary: #bababa;
    --header-accent: #e6a100;

    --text-primary: #dddddd;
    --text-secondary: #767676;
    --text-tertiary: #444444;
    --text-link: #e6a100;
    --text-warning: #ff7766;
    --text-success: #66ff77;
    --text-accent: #e6a100;

    --button-text: #444444;
    --button-text-disabled: #9f9f9f;
    --button-bg: #e6a100;
    --button-bg-hover: #c69100;
    --button-bg-disabled: #6f6f6f;

    --text-input-bg: rgba(0, 0, 0, 0.1);
    --text-input-border: rgba(0, 0, 0, 0.3);
    --text-input-border-hover: #040404;
    --text-input-warning-border: #ff7766;

    --status-error-bg: #ff7766;
    --status-error-border: #ff1100;
    --status-error-text: #ffffff;

    --status-success-bg: #66ff77;
    --status-success-border: #55dd77;
    --status-success-text: #444444;

    --status-error-button-text: #555555;
    --status-error-button-text-disabled: #f0f0f0;
    --status-error-button-bg: #ffffff;
    --status-error-button-bg-hover: #dfdfdf;
    --status-error-button-bg-disabled: #8f8f8f;

    --comboBox-options-hover: #666666;

    --checkBox-bg: #666666;
    --checkBox-bg-hover: #8c8c8c;

    --border-primary: #dddddd;

    --green: #55dd77;
    --red: #ff7766;
    --brand-color: #e6a100;
    --brand-color-dark: #b68100;
    --signOut: #ff7766;
}
