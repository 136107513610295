div#signUp {
    align-self: center;

    width: 28rem;
    background-color: var(--background-secondary);
    margin: 0 auto;
    padding: 1.5rem;
    border-radius: 1rem;

    box-shadow: 0 2px 1rem 0 rgba(0, 0, 0, 0.2);
}

div#signUp > div#title {
    margin: 0 auto 2rem auto;
    width: 100%;
}

div#signUp > div#title > h1 {
    margin: 0;
    text-align: center;
}

div#signUp > div#input {
    margin: 0 auto;
    width: 100%;
}

div#signUp > div#input > label {
    margin-bottom: 1.25rem;
}

div#signUp > div#input > button {
    width: 100%;
    margin-bottom: 0.25rem;
}

div#errorMessageBox {
    background-color: var(--status-error-bg);
    border: 1px solid var(--status-error-border);
    border-radius: 0.25rem;
    padding: 0.5rem;
    align-items: center;
    margin-bottom: 1rem;
    color: var(--status-error-text);
}

div#errorMessageBox > p {
    font-family: "IBM Plex Sans", sans-serif;
    color: var(--status-error-text);
}

div#errorMessageBox button {
    padding: 0;
    background: none;
    color: var(--status-error-text);
    text-decoration: underline;
}

div#errorMessageBox button:hover {
    opacity: 0.8;
    cursor: pointer;
}
