label#toggleButton {
    position: relative;

    display: block;
    width: 3.25rem;
    height: 1.75rem;
    border: 2px solid var(--background-floating);
    border-radius: 1rem;
    transform: translate(0, 0);
    cursor: pointer;
    background-color: var(--text-input-bg);
}

label#toggleButton > input {
    height: 100%;
    width: 100%;
    outline: none;
    appearance: none;
    margin: 0;
}

label#toggleButton > div#circle {
    position: absolute;
    top: 0.125rem;
    left: 0.125rem;
    width: 1.5rem;
    height: 1.5rem;
    background: var(--background-primary);
    border-radius: 50%;
    z-index: -1;
    transition: all .5s;
}

label#toggleButton > div#circle::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 0.5rem;
    height: 0.5rem;
    background: var(--background-floating);
    border-radius: 50%;
    transition: all .5s;
}

label#toggleButton > input:checked ~ div#circle {
    transform: translateX(100%);
}

label#toggleButton > input:checked ~ div#circle::before {
    background: var(--brand-color);
    box-shadow: 0 0 20px 1px var(--brand-color);
}