@import url("https://fonts.googleapis.com/css?family=IBM+Plex+Mono|IBM+Plex+Sans");
@import url("https://fonts.googleapis.com/css?family=IBM+Plex+Mono|IBM+Plex+Mono");
@import url("https://fonts.googleapis.com/css?family=Roboto");

html {
    font-size: 100%;
}

@media only screen and (max-width: 1200px) {
    html {
        font-size: 95%;
    }
}

@media only screen and (max-width: 1100px) {
    html {
        font-size: 92.5%;
    }
}

@media only screen and (max-width: 1000px) {
    html {
        font-size: 90%;
    }
}

@media only screen and (max-width: 900px) {
    html {
        font-size: 80%;
    }
}

@media only screen and (max-width: 800px) {
    html {
        font-size: 70%;
    }
}

@media only screen and (max-width: 700px) {
    html {
        font-size: 65%;
    }
}

@media only screen and (max-width: 600px) {
    html {
        font-size: 60%;
    }
}

@media only screen and (max-width: 550px) {
    html {
        font-size: 57.5%;
    }
}

@media only screen and (max-width: 500px) {
    html {
        font-size: 55%;
    }
}

@media only screen and (max-width: 450px) {
    html {
        font-size: 50%;
    }
}

@media only screen and (max-width: 400px) {
    html {
        font-size: 45%;
    }
}

@media only screen and (max-width: 350px) {
    html {
        font-size: 40%;
    }
}

h1 {
    margin: 0;

    font-family: "IBM Plex Mono", sans-serif;
    font-size: 3rem;
    line-height: 4rem;
    color: var(--header-primary);
}

h1 > b {
    color: var(--header-accent);
}

h2 {
    margin: 0;

    font-family: "IBM Plex Mono", sans-serif;
    font-size: 2.5rem;
    line-height: 3.5rem;
    color: var(--header-primary);
}

h2 > b {
    color: var(--header-accent);
}

h3 {
    margin: 0;

    font-family: "IBM Plex Sans", sans-serif;
    font-size: 1.5rem;
    line-height: 2rem;
    font-weight: 600;
    color: var(--header-accent);
}

h3.whiteColor {
    color: var(--header-primary);
}

h4 {
    margin: 0;

    font-family: "IBM Plex Sans", sans-serif;
    font-size: 1.5rem;
    line-height: 2rem;
    font-weight: 400;
    color: var(--header-accent);
}

h4.errorColor {
    color: var(--status-error-text);
}

h5 {
    margin: 0;

    font-family: "IBM Plex Sans", sans-serif;
    font-size: 1.25rem;
    line-height: 2rem;
    font-weight: 600;
    color: var(--header-accent);
}

h6 {
    margin: 0 0 5px;

    font-family: "IBM Plex Sans", sans-serif;
    font-size: 1.125rem;
    line-height: 1.5rem;
    font-weight: 400;
    color: var(--header-secondary);
}

p {
    margin: 0;

    font-family: "Roboto", sans-serif;
    font-size: 1rem;
    line-height: 1.25rem;
    color: var(--text-primary);
}

p > b {
    color: var(--text-accent);
}

p.errorColor {
    color: var(--status-error-text);
}

a {
    font-family: "Roboto", sans-serif;
    font-size: 1rem;
    color: var(--text-link);
}
