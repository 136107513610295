html {
    height: 100%;
    user-select: none;
    -webkit-user-select: none;
}

body {
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    background-color: var(--background-primary);
    display: flex;
}

body > div#root {
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    background-color: var(--background-primary);
    display: flex;
}

/* Button */
button {
    color: var(--button-text);
    background-color: var(--button-bg);

    font-family: "IBM Plex Sans", sans-serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.25rem;
    padding: 0.75rem;
    text-align: center;

    border-radius: 0.25rem;
    border-style: none;
}

button:hover {
    background-color: var(--button-bg-hover);
    cursor: pointer;
}
