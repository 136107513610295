div#changeUsername {
    display: inline-grid;
    width: 22rem;
    height: min-content;
    padding: 1.5rem;
    border-radius: 0.5rem;
    margin: 0 1.5rem 1.5rem 0;

    background-color: var(--background-secondary);
}

div#changeUsername > h4 {
    margin-bottom: 0.25rem;
}

div#changeUsername > div > label {
    margin-bottom: 1rem;
}

div#changeUsername > div > button {
    float: right;
}

/* PopUp */

div#popUpBackground {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: rgba(100, 100, 100, 0.5);
}

div#popUpBackground > div.popUp {
    position: absolute;
    left: 50%;
    top: 50%;

    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);

    padding: 1.5rem;
    border-radius: 1rem;
    background-color: var(--background-primary);
}

div#errorPopUp > button {
    width: 45%;
    float: right;
    margin-top: 1rem;
}

div#errorMessageBox {
    background-color: var(--status-error-bg);
    border: 1px solid var(--status-error-border);
    border-radius: 0.25rem;
    padding: 0.5rem;
    align-items: center;
    margin-bottom: 1rem;
    color: var(--status-error-text);
}

div#errorMessageBox > p {
    font-family: "IBM Plex Sans", sans-serif;
    color: var(--status-error-text);
}

div#errorMessageBox button {
    padding: 0;
    background: none;
    color: var(--status-error-text);
    text-decoration: underline;
}

div#errorMessageBox button:hover {
    opacity: 0.8;
    cursor: pointer;
}

div#successMessageBox {
    padding: 0.5rem;
    align-items: center;
    color: var(--text-success);
    float: left;
}

div#successMessageBox > p {
    font-family: "IBM Plex Sans", sans-serif;
    color: var(--status-success-text);
}
