button#button {
    padding: 0.25rem 0.75rem 0.25rem 0.25rem;
    height: 2.5rem;

    display: inline-flex;
    align-items: center;
}

button#button:hover {
    cursor: pointer;
}

button#button > label#icon {
    display: inline-block;
    width: 2rem;
    height: 2rem;
}

button#button > label#icon:hover {
    cursor: pointer;
}

button#button > label#text {
    width: calc(100% - 2.5rem);
    margin-left: 0.75rem;
}

button#button > label#text:hover {
    cursor: pointer;
}
