div#passwordManagerList {
    height: 20%;
    width: 25rem;

    margin: 1rem 0;

    display: flex;
    flex-flow: column;
    flex-grow: 1;
}

div#passwordManagerList ~ button {
    margin-bottom: 0.5rem;
}

div#passwordManagerList > div {
    height: 100%;

    display: flex;
    flex-flow: column;

    padding: 0 1.5rem;
    background-color: var(--background-secondary);
    border-radius: 0.5rem;
}

div#passwordManagerList > div > hr {
    height: 1.5rem;
    width: calc(100% + 3rem);

    border: none;
    position: relative;
    flex-shrink: 0;
}

div#passwordManagerList > div > hr#topBorder {
    background: linear-gradient(
        to bottom,
        rgba(30, 30, 30, 1),
        rgba(0, 0, 0, 0)
    );
    border-radius: 0.5rem 0.5rem 0 0;
    margin: 0 0 -1.5rem -1.5rem;
}

div#passwordManagerList > div > hr#bottomBorder {
    background: linear-gradient(to top, rgba(30, 30, 30, 1), rgba(0, 0, 0, 0));
    border-radius: 0 0 0.5rem 0.5rem;
    margin: -1.5rem 0 0 -1.5rem;
}

div#passwordManagerList > div > div {
    height: 100%;
    padding: 1.5rem 0;
    overflow-y: auto;
    overflow-x: hidden;

    scrollbar-width: thin;
    scrollbar-color: var(--background-floating) var(--background-secondary);
}

div#passwordManagerList > div > div::-webkit-scrollbar {
    width: 0.5rem;
}
div#passwordManagerList > div > div::-webkit-scrollbar-track {
    background: var(--background-tertiary);
}
div#passwordManagerList > div > div::-webkit-scrollbar-thumb {
    background-color: var(--background-floating);
    border: transparent;
}

div#passwordManagerList > div > div > div.listElement {
    height: 2.5rem;
}

div#passwordManagerList > div > div > div.listElement > img {
    display: none;

    height: 1.25rem;
    margin: 0.5rem 0 0.5rem 0;
    float: left;

    transition: transform 0.5s;
}

div#passwordManagerList > div > div > div.listElement > p {
    padding: 0.5rem 0 0.5rem 0;
    align-items: center;

    transition: color 0.5s;
    transition: transform 0.5s;
}

div#passwordManagerList > div > div > div.listElement:hover > p {
    color: var(--text-accent);
    transform: translate(0.5rem);
}

div#passwordManagerList > div > div > div.listElement > hr {
    color: var(--background-floating);
    margin: 0;
    border: none;
    border-top: 2px var(--background-floating) solid;
    width: calc(100% - 0.5rem);
}

div#passwordManagerList > div > div > div.listElement.selectedElement > img {
    display: block;
}

div#passwordManagerList
    > div
    > div
    > div.listElement.selectedElement:hover
    > img {
    display: block;
    float: left;

    transform: translate(0.5rem);
}

div#passwordManagerList > div > div > div.listElement.selectedElement > p {
    width: calc(100% - 2.5rem);
    margin-left: 0.5rem;
    float: left;
    color: var(--text-accent);
}

div#passwordManagerList
    > div
    > div
    > div.listElement.selectedElement:hover
    > p {
    display: block;
    float: left;

    transform: translate(0.25rem);
}
