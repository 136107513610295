label#inputLabel {
    display: inline-flex;
    width: 100%;
}

label#inputLabel > input {
    color: var(--text-primary);
    background-color: var(--text-input-bg);

    font-family: "IBM Plex Sans", sans-serif;
    font-size: 1rem;
    line-height: 1.25rem;
    padding: 0.5rem 0.5rem 0.5rem 2.5rem;

    border-radius: 0.25rem;
    border: 1px solid var(--text-input-border);
    transition: border-color 0.2s ease-in-out;
    width: calc(100% - 3rem);
}

label#inputLabel > input:hover {
    border: 1px solid var(--text-input-border-hover);
    opacity: 0.9;
    cursor: pointer;
}

label#inputLabel > input:focus-visible {
    border: 1px solid var(--brand-color);
    outline: none;
}

label#inputLabel > input:required {
    border: 1px solid var(--text-input-warning-border);
    outline: none;
}

label#inputLabel > i#icon {
    margin: 0 0 0 0.5rem;

    position: absolute;
    height: 1.25rem;
    width: 1.25rem;
    align-self: center;
}