div#passwordData {
    width: 35rem;
    padding: 1.5rem;
    background-color: var(--background-secondary);
    border-radius: 1rem;
    box-shadow: 1rem 1rem 1rem rgba(38, 38, 38, 0.8);
}

div#navigationIcons {
    display: grid;
    grid-template-columns: 3rem 28rem 3rem;
    column-gap: 0.5rem;
}

div#navigationIcons > img:hover {
    cursor: pointer;
}

div#navigationIcons > img#arrowLefticon {
    grid-column: 1;
}

div#navigationIcons > img#penIcon {
    grid-column: 3;
    margin: 0.5rem;
}

div#headline {
    height: 3.5rem;

    margin-bottom: 1rem;
}

div#headline > h3 {
    height: 2rem;
    padding: 0.5rem 0.5rem 0.5rem 1rem;
    user-select: text;
    -webkit-user-select: text;
}

div#data > p {
    font-family: "IBM Plex Sans", sans-serif;
    height: 1.25rem;
    padding: 0.5rem 0.5rem 0.5rem 1rem;
    margin-bottom: 1rem;
    user-select: text;
    -webkit-user-select: text;
}

div#data > p#notes {
    height: 6.25rem;
    margin-bottom: 0;
}

div#data > div#password {
    position: relative;
    height: 2.25rem;
    margin-bottom: 1rem;
    display: grid;
    grid-template-columns: 32rem 2rem;
    column-gap: 0.5rem;
}

div#data > div#password > p {
    font-family: "IBM Plex Sans", sans-serif;
    grid-column: 1;
    height: 1.25rem;
    padding: 0.5rem 0.5rem 0.5rem 1rem;
    user-select: text;
    -webkit-user-select: text;
}

div#data > div#password > img#copyIcon {
    width: 2rem;
    height: 2rem;
    margin: 0.125rem;
    grid-column: 2;
}

div#data > div#password > img#copyIcon:hover {
    cursor: pointer;
}

div#data > div#password > div#copied {
    width: 3.5rem;
    font-family: "IBM Plex Sans", sans-serif;
    display: block;
    position: absolute;
    top: -2.25rem;
    right: -1rem;
    padding: 0.25rem 0.5rem;
    background: var(--green);
    color: var(--text-secondary);
    z-index: 9;
    font-size: 1rem;
    line-height: 1.25rem;
    border-radius: 0.5rem;
    white-space: nowrap;
    word-wrap: normal;

    transition: opacity 0.5s;

    opacity: 0;
}

div#data > div#password > div#copied.show {
    opacity: 1;
}

div#data > div#password > div#copied:after {
    width: 0.75rem;
    height: 0.75rem;
    background-color: var(--green);
    clip-path: polygon(100% 0%, 50% 75%, 0% 0%);
    content: "";
    display: block;
    position: absolute;
    left: calc(50% - 0.25rem);
    top: 1.75rem;
    z-index: 8;
}

div#data > div#password > div#copied > p {
    text-align: center;
    color: var(--text-tertiary);
}

@media only screen and (max-width: 1500px) {
    div#passwordDataBackground {
        position: fixed;
        top: 0;
        width: calc(100% - 20rem);
        left: 20rem;
        height: 100%;
        background-color: rgba(50, 50, 50, 0.6);

        display: flex;
        justify-content: center;
        align-items: center;
    }
}

@media only screen and (max-width: 1000px) {
    div#passwordDataBackground {
        width: calc(100% - 3.5rem);
        left: 3.5rem;
    }
}
